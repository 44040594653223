// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container {
  background: #fafafa;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  padding: 10px 0;
}

.logo-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.logo {
  max-width: 150px; /* Adjusted for responsiveness */
  height: auto;
}

@media (max-width: 1024px) {
  .logo-container {
    padding: 30px 0;
  }
  .logo-list {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .logo-container {
    padding: 40px 0;
  }
  .logo-list {
    gap: 20px;
  }

  .logo {
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .logo-container {
    padding: 40px 0;
  }
  .logo {
    max-width: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/guest-view/home/LogoList.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,cAAc;EACd,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,gBAAgB,EAAE,gCAAgC;EAClD,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,SAAS;EACX;;EAEA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".logo-container {\n  background: #fafafa;\n  max-width: 1920px;\n  margin: 0 auto;\n  width: 100%;\n  padding: 10px 0;\n}\n\n.logo-list {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 50px;\n}\n\n.logo {\n  max-width: 150px; /* Adjusted for responsiveness */\n  height: auto;\n}\n\n@media (max-width: 1024px) {\n  .logo-container {\n    padding: 30px 0;\n  }\n  .logo-list {\n    gap: 30px;\n  }\n}\n\n@media (max-width: 768px) {\n  .logo-container {\n    padding: 40px 0;\n  }\n  .logo-list {\n    gap: 20px;\n  }\n\n  .logo {\n    max-width: 120px;\n  }\n}\n\n@media (max-width: 480px) {\n  .logo-container {\n    padding: 40px 0;\n  }\n  .logo {\n    max-width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
