// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_logo_list_2 {
  padding: 40px 20px;
  text-align: center;
}

.logo_list_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

.logo_img {
  max-width: 100%;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .logo_list_wrapper {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .home_logo_list_2 {
    padding: 30px 15px;
  }

  .logo_list_wrapper {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .home_logo_list_2 {
    padding: 20px 10px;
  }

  .logo_img {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/guest-view/home/LogoList2.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA,sBAAsB;AACtB;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,SAAS;EACX;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".home_logo_list_2 {\n  padding: 40px 20px;\n  text-align: center;\n}\n\n.logo_list_wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 40px;\n  justify-content: center;\n}\n\n.logo_img {\n  max-width: 100%;\n  height: auto;\n}\n\n/* Responsive Styles */\n@media (max-width: 1024px) {\n  .logo_list_wrapper {\n    gap: 30px;\n  }\n}\n\n@media (max-width: 768px) {\n  .home_logo_list_2 {\n    padding: 30px 15px;\n  }\n\n  .logo_list_wrapper {\n    flex-direction: column;\n    gap: 20px;\n  }\n}\n\n@media (max-width: 480px) {\n  .home_logo_list_2 {\n    padding: 20px 10px;\n  }\n\n  .logo_img {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
